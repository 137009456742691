import React, { useState, useEffect, useMemo } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

//imported
import { BANNER_MANAGEMENT } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import Validation from './Validation'
import LoadingButton from "components/shared/LoadingButton";
import { STATUS_ENUM } from "common/constants";
import { Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { updateBanner, viewBanner } from "store/services/bannersService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CKEditorField from "components/shared/CKEditorField";

const AddEdit = ({ dispatch }) => {
	const history = useHistory();
	const { id: editkey } = useParams();

	const [bannerData, setBannerData] = useState();

	const initialValues = useMemo(() => {
		if(!bannerData) {
			return {};
		}

		return {
			title: bannerData ? bannerData.title : "",
			message: bannerData ? bannerData.message : "",
			status: bannerData ? bannerData.status : "active",
			role: bannerData.role
		};
	}, [bannerData]);

	useEffect(() => {
		if (editkey) {
			getData();
		}
	}, []);

	//get detail
	async function getData() {
		try {
			dispatch(actions.persist_store({ loader: true }));
			let res = await viewBanner(editkey);
			setBannerData(res?.body)
			dispatch(actions.persist_store({ loader: false }));
		} catch (err) {
			dispatch(actions.persist_store({ loader: false }));
			console.log(err);
		}
	}

	// add edit
	const handleSubmit = async (values) => {
		try {
			let payload = {
				bannerId: editkey,
				data: values
			}
			dispatch(actions.persist_store({ loader: true }));
			await updateBanner(payload);
			history.push(Path.banner_management)
			dispatch(actions.persist_store({ loader: false }));

		} catch (err) {
			console.log(err);
			dispatch(actions.persist_store({ loader: false }));
		}
	}

	return (
		<>
			<Helmet title={BANNER_MANAGEMENT.CURRENT_MODULE} />
			<section className="section">
				<div className="section-header">
					<h1>{BANNER_MANAGEMENT.CURRENT_MODULE}</h1>
					<div className="section-header-breadcrumb">

						<div className="breadcrumb-item"><Link to={Path.banner_management}>Manage Banners</Link></div>
						<div className="breadcrumb-item">Edit Banner</div>
					</div>
				</div>
				{/* {!props.loader && */}
				<div className="section-body">
					<h2 className="section-title">Edit Banner</h2>
					<p className="section-lead">
						On this page you can edit the banner.
					</p>
					<div className="row mt-sm-4">
						<div className="col-8 col-md-8 col-lg-8">
							<div className="card">
								<div className="card-body">
			
									<Formik
										initialValues={initialValues}
										validationSchema={Validation}
										onSubmit={handleSubmit}
										enableReinitialize
									>
										{({
											values,
											errors,
											touched,
											isSubmitting,
											handleSubmit,
											handleChange,
											handleBlur,
											setFieldValue
										}) => (
											<div className="card">
												{console.log({values,
											errors})}
												<div className="card-body">
													<Form onSubmit={handleSubmit}>
														<Row>
															<div
																className={`form-group col-12`}
															>
																<label>Location</label>
																<p>
																	{values.role === 3 ? (
																		"Practitioner Panel"
																	):values.role === 4 ? (
																		"Client Panel"
																	):(
																		""
																	)}
																</p>
															</div>
															<div
																className={`form-group col-12`}
															>
																<label>Title</label>
																<input
																	type="text"
																	className={`form-control ${errors["title"]
																		? "is-invalid"
																		: ""
																		} `}
																	name="title"
																	placeholder={"Title"}
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.title}
																/>
																{errors["title"] && touched["title"] && (
																	<p className="invalid-feedback">{errors["title"]}</p>
																)}
															</div>
															<div
																className={`form-group col-12`}
															>
																<label>Message</label>
																<CKEditorField
																	field="message"
																	setFieldValue={setFieldValue}
																	value={values.message || ""}
																	errors={errors}
																/>
																{errors["message"] && touched["message"] && (
																	<p className="invalid-feedback">{errors["message"]}</p>
																)}
															</div>
															<div
																className={`form-group col-12`}
															>
																<label>Status</label>
																<div className="row">
																	{STATUS_ENUM.map((option, index) => (
																		<div className="form-group col-md-6" key={index}>
																			<input
																				type="radio"
																				className="form-radio"
																				name="status"
																				onChange={handleChange}
																				value={option.value}
																				checked={values.status === option.value}
																			/>
																			<label>&nbsp;{option.label}</label>
																		</div>
																	))}
																</div>
																{errors["status"] && touched["status"] && (
																	<p className="invalid-feedback">{errors["status"]}</p>
																)}
															</div>
															<div className="form-group col-12 button-submit">
																<LoadingButton
																	type="submit"
																	className="btn btn-primary btn-lg"
																	loading={isSubmitting}
																	disabled={isSubmitting}
																>
																	{isSubmitting ? "Please Wait..." : "Save"}
																</LoadingButton>
															</div>
														</Row>
													</Form>
												</div>
											</div>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* } */}
			</section>
		</>
	)
}


const mapStateToProps = (state) => {
	return {
	}
};

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);