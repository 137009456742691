import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

//imported
import { DELETE_ALERT } from "common/constantMessage";
import {
  deleteContentPageData,
} from "store/services/contentPageService";
import { FORM_MANAGEMENT } from "common/viewContent";
import * as actions from "store/actions";
import { UC_FIRST } from "helpers/common";
import { getClientCategories, getFormsList, updateFormDetails } from "store/services/formsService";
import { Button, Form } from "react-bootstrap";

const List = ({
  dispatch,
  formsList,
  clientCategories
}) => {
  const [filter, setFilter] = useState({
    client_category: ""
  });
  //pagination
  //get category detail
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getFormsList(filter));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  async function getCategories() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getClientCategories());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    getCategories();
  }, []);
  
  useEffect(() => {
    getData();
  }, [filter]);

  const handleChangeFilter = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(deleteContentPageData(id)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditForm = async (formId, data) => {
    try {
      let payload = {
        formId,
        data
      }
      dispatch(actions.persist_store({ loader: true }));
      await updateFormDetails(payload);
      dispatch(actions.persist_store({ loader: false }));
      await getData();
    } catch(err) {
      console.error("Error saving form details", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
      <Helmet title={FORM_MANAGEMENT.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{FORM_MANAGEMENT.CURRENT_MODULE}</h1>
          {/* <div className="section-header-button">
            <Link to={Path.content_add} className="btn btn-primary">
              Add Content
            </Link>
          </div> */}
          <div className="section-header-breadcrumb">
            
            <div className="breadcrumb-item">Form management</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{FORM_MANAGEMENT.CURRENT_MODULE}</h2>
          <p className="section-lead">
            You can manage all default forms here.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{FORM_MANAGEMENT.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="searchForm position-relative iconWithText"></div>
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Category:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="client_category"
                          className="form-control me-3"
                          onChange={handleChangeFilter}
                        >
                          <option value="">All</option>
                          {clientCategories.map((cat, i) => (
                            <option key={i} value={cat.uuid}>{cat.name}</option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Form Visibility</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      {formsList.length > 0 ? (
                        formsList.map((item, i) => {
                          return (
                            <FormRow
                              key={i}
                              index={i}
                              item={item}
                              onEdit={handleEditForm}
                              categories={clientCategories}
                            />
                          )
                        })
                      ):(
                        <tr>
                          <td colSpan={6} className="text-center">No data found</td>
                        </tr>
                      )}
                    </table>
                  </div>
                  {/* {formsList.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const FormRow = ({ item, index, categories, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formType, setFormType] = useState(item.form_type);
  const [clientCategory, setClientCategory] = useState(item.client_category);
  const [status, setStatus] = useState(item.status);

  const handleSave = () => {
    setIsEditing(false);
    onEdit(item.id, { form_type: formType, client_category: clientCategory || null, status });
  };

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
          {item.form_name}
      </td>
      <td>
        {isEditing ? (
          <Form.Select
            aria-label="Default select example"
            name="status"
            className="form-control me-3"
            value={formType}
            onChange={(e) => setFormType(e.target.value)}
          >
            <option value="practitioner">Practitioner Facing</option>
            <option value="client">Client Facing</option>
            <option value="both">Both</option>
          </Form.Select>
        ) : (
          UC_FIRST(item.form_type)
        )}
      </td>
      <td>
        {isEditing ? (
          <Form.Select
            aria-label="Default select example"
            name="status"
            className="form-control me-3"
            value={clientCategory || ""}
            onChange={(e) => setClientCategory(e.target.value)}
          >
            {categories.map((cat, i) => (
              <option key={i} value={cat.uuid}>{cat.name}</option>
            ))}
            <option key={"both"} value="">Both</option>
          </Form.Select>
        ) : (
          item.client_category_name || "Both"
        )}
      </td>
      <td>
        {isEditing ? (
          <Form.Select
            aria-label="Default select example"
            name="status"
            className="form-control me-3"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Form.Select>
        ) : (
          <div className={`badge text-white badge-${item.status === "active" ? "primary" : "danger"}`}>
            {item.status}
          </div>
        )}
      </td>
      <td>
          {isEditing ? (
            <>
              <Button variant="transparent" onClick={handleSave} title="Save">
                <i className="fa fa-lg fa-save"></i>
              </Button>
              <Button variant="transparent" onClick={() => setIsEditing(false)} title="Cancel">
                <i className="fa fa-lg fa-times"></i>
              </Button>
            </>
          ) : (
            <Button
              variant="transparent"
              onClick={() => setIsEditing(true)}
              title="Edit"
            >
              <i className="fa fa-lg fa-edit"></i>
            </Button>
          )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    formsList: state.DefaultForms.forms,
    clientCategories: state.DefaultForms.clientCategories,
    itemsCountPerPage: state.DefaultForms.itemsCountPerPage,
    totalItemsCount: state.DefaultForms.totalItemsCount,
    loader: state.DefaultForms.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
