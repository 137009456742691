import React from 'react';
// import { CKEditor} from '@ckeditor/ckeditor5-react';
// import ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import CustomUploadAdapter from "utils/constants/CkEditorCustomImageUpload";
import { MAIN_PATH, UPLOAD_IMAGE_PATH } from "store/services/apiPath";
import CustomImagePlugin from "utils/CkEditorPasteHandler";
import { urlToBase64 } from "helpers/common";
import { uploadImage_ } from "store/services/fileService";
import { useDispatch } from "react-redux";

const uploadUrl = `${process.env.REACT_APP_API_BASE_URL}/${MAIN_PATH}${UPLOAD_IMAGE_PATH}`;

const editorConfiguration = {
  // plugins: [CustomImagePlugin],
  toolbar: [
    'heading', '|', 'undo', 'redo',
    'bold', 'italic', 'link', '|', "removeFormat",
    'bulletedList', 'numberedList', '|',
    'blockQuote', 'insertTable', '|',
    'mediaEmbed', 'imageInsert', 'fileBrowser', 'exportPdf', 'exportWord', 'exportXml', '|',
    'undo', 'redo', "sourceEditing", "htmlSupport"
  ],
  sourceEditing: {
    allowCollaborationFeatures: true,
    preview: true, // Enable the preview feature
    highlightActiveLine: true, // Enable highlighting the line the cursor is on
    highlightMatches: true // Enable highlighting matching tag pairs
  },
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true
      }
    ]
  },
  // simpleUpload: {
  //   // The URL that the images are uploaded to.
  //   uploadUrl: 'http://example.com',
  // }
};
const CKEditorField = ({ field, setFieldValue, value, errors, ...props }) => {
  const classNames = `ckeditor-control ${errors[field] && 'is-invalid'}`;

  const handleReady = (editor) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader, uploadUrl);
    };
  };

  return (
    <CKEditor
      editor={Editor}
      config={editorConfiguration}
      data={value}
      onReady={handleReady}
      onChange={(event, editor) => {
        const data = editor.getData();
        // console.log( { event, editor, data } );
        setFieldValue(field, editor.getData());
      }}
      className={classNames}
      // onBlur={field.onBlur}
      {...props}
    />
  );
};
export default CKEditorField;