import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
//imported
import { SIDEBAR_LOGO } from "utils/constants/constant";
import { useCustomHistory } from "common/getHistory";
import { useModules } from "common/modules";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";

const Sidebar = (props, user) => {
  const [roleData, setRoleData] = useState();

  useEffect(() => {
    const localdata = localStorage.getItem("user");
    if (localdata) {
      const data = JSON.parse(localdata);
      setRoleData(data.role);
    }
  }, [user]);
  //open close Dropdowun
  const [dropdownStates, setDropdownStates] = useState(false);
  const [dropUrl, setDropurl] = useState("");
  const [currentId, setCurrentId] = useState(0);

  //history
  const path = useCustomHistory();
  //sidebar
  let modules = useModules();
  const modulesRefs = useRef([]);

  const sidebarHideShow = (elementId) => {
    localStorage.setItem("currentScrollId", elementId);
  };
  useEffect(() => {
    let currentStateId = localStorage.getItem("currentScrollId");
    currentStateId = currentStateId ? JSON.parse(currentStateId) : 0;
    if (modulesRefs.current[currentStateId]) {
      modulesRefs.current[currentStateId].scrollIntoView({
        behavior: "smooth",
      });
    }
    sidebarHideShow(currentStateId);
  }, [modules, modulesRefs]); // Dependencies that trigger the useEffect

  const sidebarStyle = {
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    outline: "none",
  };
  const toggleDropdown = (dropUrl) => {
    if (path === dropUrl) {
      setDropdownStates(!dropdownStates);
    } else setDropdownStates(true);
  };
  const toggleDropdown_ = (url) => {
    setDropurl(url);
    toggleDropdown(url);
  };

  return (
    <div className="main-sidebar sidebar-style-2" style={sidebarStyle}>
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <Link to="/" style={{ cursor: "pointer" }}>
            <img src={SIDEBAR_LOGO} className="main-sidebar-logo img-fluid" />
          </Link>
        </div>
        <div className="sidebar">
          <ul className="sidebar-menu">
            {modules.length > 0 &&
              modules.map((value, key) => {
                let module = value.sub_modules ? value.sub_modules : "";
                let sub_module = value.is_modules
                  ? path === value.url ||
                    path === module.form ||
                    path === module.edit ||
                    path === module.view
                  : path === value.url;
                return (
                  <>
                    {roleData == 1 ? (
                      <React.Fragment key={key}>
                        {value.is_sidebar && !value.is_dropdown && (
                          <li
                            ref={(el) => (modulesRefs.current[key] = el)}
                            className={classNames("", { active: sub_module })}
                            to={value.url}
                          >
                            {/* <OverlayTrigger
                          placement="right"
                          arrow={false}
                          overlay={<Tooltip id="tooltip">{value.name}</Tooltip>}
                        > */}
                            <NavLink
                              className="nav-link"
                              onClick={() => sidebarHideShow(key)}
                              to={value.url}
                              //   title={value.name}
                            >
                              {/* <i className={value.sidebar_icon}></i>{" "} */}

                              {value.sidebar_icon}
                              <span>{value.name}</span>
                            </NavLink>
                            {/* </OverlayTrigger> */}
                          </li>
                        )}
                        {value.is_dropdown && (
                          <li
                            className={`dropdown ${
                              dropdownStates ? "active" : ""
                            }`}
                          >
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item
                                className="border-0 bg-transparent"
                                eventKey="1"
                              >
                                <Accordion.Header className="border-0 bg-trnasparent p-0">
                                  {value.sidebar_icon}
                                  <span className="w-100">{value.name}</span>
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                  {value.dropdown_module.length > 0 && (
                                    <ul className="ps-0 mb-0">
                                      {value.dropdown_module.map((drop, i) => {
                                        return (
                                          <li
                                            className={`dropdown ${
                                              drop.url === path ? "active" : ""
                                            }`}
                                            onClick={() =>
                                              toggleDropdown_(drop.url)
                                            }
                                          >
                                            <Link
                                              className="nav-link"
                                              to={drop.url}
                                            >
                                              {drop.name}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </li>
                        )}
                      </React.Fragment>
                    ) : value.is_permission ? (
                      <React.Fragment key={key}>
                        {value.is_sidebar && !value.is_dropdown && (
                          <li
                            ref={(el) => (modulesRefs.current[key] = el)}
                            className={classNames("", { active: sub_module })}
                            to={value.url}
                          >
                            <NavLink
                              className="nav-link"
                              onClick={() => sidebarHideShow(key)}
                              to={value.url}
                            >
                              {value.sidebar_icon}
                              <span>{value.name}</span>
                            </NavLink>
                          </li>
                        )}
                        {value.is_dropdown && (
                          <li
                            className={`dropdown ${
                              dropdownStates ? "active" : ""
                            }`}
                          >
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item
                                className="border-0 bg-transparent"
                                eventKey="1"
                              >
                                <Accordion.Header className="border-0 bg-trnasparent p-0">
                                  {value.sidebar_icon}
                                  <span className="w-100">{value.name}</span>
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                  {value.dropdown_module.length > 0 && (
                                    <ul className="ps-0 mb-0">
                                      {value.dropdown_module.map((drop, i) => {
                                        return (
                                          <li
                                            className={`dropdown ${
                                              drop.url === path ? "active" : ""
                                            }`}
                                            onClick={() =>
                                              toggleDropdown_(drop.url)
                                            }
                                          >
                                            <Link
                                              className="nav-link"
                                              to={drop.url}
                                            >
                                              {drop.name}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </li>
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
          </ul>
        </div>
      </aside>
    </div>
  );
};
const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    loader: state.persistStore.loader,
    user: state.Auth,
  };
};

export default connect(mapStateToPros)(Sidebar);
